.skeleton-header-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 15%;
    padding-left: 15%;
}

.skeleton-header-image{
  width: 100%;
  padding-bottom: 3rem;
}

.home-component {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 15%;
    padding-left: 15%;
}

.divider {
    display: flex;
    background: orange;
    height: 8px;
    width: 35%;
    margin-bottom: 20px;
    align-self: center;
}

.divider-contact {
  margin-top: 3rem;
}

.header-title {
  font-family: AvenirNext-DemiBold;
  font-size: 1.6rem;
  color: #4A4A4A;
  text-align: center;
  font-weight: 600;
  padding-bottom: 1rem;
}

.header-phrase {
  padding-bottom: 3rem;
  text-align: center;
  font-size: 3.8rem;
  line-height: 5.6rem;
  font-weight: 700;
}

.highlight-para {
  font-size: 2.2rem;
  text-align: center;
}

.skeleton-paragraphs-content {
  width: 60%;
  font-weight: 400;
  padding-top: 2rem;
  padding-bottom: 3rem;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-text-content {
  width: 70%;
}

.paragraphs-title {
  font-size: 2.0rem;
  font-weight: 700;
  line-height: 4.0rem;
  padding-top: 3rem;
}

a.contact-form-link {
  font-size: 18px;
  color: #007BFF;
  width: fit-content;
  padding: 8px 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    
  &:hover {
    color: #0056b3 !important;
    text-decoration: underline solid
  #0056b3;
  }
}

.paragraph-link {
  padding-bottom: 32px;
}

.row {
    padding: 30px 0;
}

// mobile css for skeleton
@media only screen and (max-width: 900px) {
    .skeleton-header-image-container {
        padding-right: 5%;
        padding-left: 5%;
    }

    .home-component {
        padding-right: 10%;
        padding-left: 10%;
    }
}

@media only screen and (max-device-width: 480px) {
  .header-phrase {
    padding-bottom: 2rem;
    text-align: center;
    font-size: 1.8rem;
    line-height: 2.1rem;
    font-weight: 600;
  }

  .highlight-para {
    font-size: 1.5rem;
    text-align: center;
  }

  .skeleton-text-content {
    width: 100%;
  }

  .skeleton-paragraphs-content {
    width: 90%;
  }

  .skeleton-img {
    padding-bottom: 1rem;
  }
}
