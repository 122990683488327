.form-title {
    font-size: 22px;
    text-align: center;
    padding-bottom: 1rem;
}

.pdfs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
}

.pdf-button {
    margin: 5px;
    width: 70%;
    background-color: #F28729!important;
    border-color: #F28729!important;
}

.pdf-dropdown {
    margin: 5px!important;
    // width: 70%!important;
    // color: #fff!important;
    border-radius: .25rem;
    // white-space: pre-wrap!important;
    background-color: #F28729!important;
    border-color: #F28729!important;
}

.pdf-dropdown.show { background-color: #F28729!important; }

.accordion-link {
    text-align: left;
}

.styled-link {
    font-weight: 400;
    color: #007bff!important;
    cursor: pointer;
}

.faq-container {
    padding-bottom: 2rem;
}

.faq-question {
    text-align: center;
    font-size: 15x;
    line-height: 5.6rem;
    font-weight: 700;
}

.faq-answer {
    padding-left: 15px;
    font-size: 15x;
    font-weight: 500;
    padding-right: 8px;
}

.owner-relations-contact-title {
    font-size: 21px;
    font-weight: 700;
    line-height: 4.0rem;
}

.vendors-content {
    display: flex;
    flex-direction: column;
}

.split-content {
    display: flex;
    flex-direction: row;
}

.accordion-override {
    width: 30rem!important;
}

.energy-link-title {
    padding-top: 1rem;
    font-size: 22px;
    padding-bottom: 1rem;
}

.energyLink-iframe{
    padding-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.owner-relations-contact-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 15%;
    padding-left: 15%;
    padding-bottom: 1rem;
    text-align: center;
}

// mobile css for blog
@media only screen and (max-width: 900px) {
    .split-content {
        display: flex;
        flex-direction: column;
    }
    .accordion-override {
        width: 100%!important;
    }
    .energyLink-iframe {
        padding-right: 0!important;
    }
  }
  