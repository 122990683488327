.home-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vimeo-iframe {
  padding-bottom: 8rem;

  @media (max-width: 768px){
    height: 25rem;
    width: 24rem;
  }
}