.logo {
  max-width: 11%;
  @media (max-width: 794px){
    display: none;
  }
}

.mobile-logo {
  @media (min-width: 768px){
    display: none;
  }
  position: absolute;
  margin-bottom: 10rem;
  top: 12px;
  right: 10;
  left: 17rem;
  left: 43%;
  max-width: 18%;
}

.nav-wrapper {
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10px;
}

.nav-link {
  font-family: AvenirNext-DemiBold;
  font-size: 13px;
  color: #4A4A4A;
  text-align: left;
  padding-right: 40px;
}

a.nav-link:hover {
  text-decoration: underline solid
  #F28729;
  color: #4A4A4A!important;
}


// mobile css for nav bar
@media (min-width: 793px) {
    // toto figure out how to manage this with mobile, disabled on ceratin screen sizes
  .bg-light {
    background-color: white !important;
  }

  .navbar-nav {
    align-items: center;
    justify-content: space-between;
  }

  .navbar {
    height: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}