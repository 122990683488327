.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F7F7F9;
}

.contact-form {
  justify-content: center;
  padding: 50px;
  padding-top: 30px;
  padding-bottom: 50px;
  padding-right: 50px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.form-group {
  padding: 3%;
  width: 100%;
}

// mobile css for contact form
@media only screen and (max-device-width: 480px) {
  .contact-form {
    justify-content: center;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
  }

  .contact-dual {
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: baseline !important;
  }

  .offices-wrapper {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    width: 80% !important;
  }
}

.contact-dual {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: baseline;
}

.offices-wrapper {
  width: 42%;
}

.offices-inner {
  padding: 1rem;
}

.office-title {
  font-weight: 600;
}
