@use "./scss/blog.scss";
@use "./scss/contact_form.scss";
@use "./scss/footer.scss";
@use "./scss/jobs.scss";
@use "./scss/Management.scss";
@use "./scss/nav.scss";
@use "./scss/skeleton.scss";
@use "./scss/video.scss";
@use "./scss/vendors.scss";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  background: #F7F7F9;
}