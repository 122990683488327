.blog-overview {
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 50%;
}

a.blog-section {
  text-decoration: inherit;
  color: inherit;
}

.blog-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.blog-summary-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F28729;
  color: white!important;
  padding-top: 30px;
  padding-bottom: 20px;
}

.blog-date {
  font-family: AvenirNext-Medium;
  font-size: 16px;
  color: #4A4A4A!important;
  text-align: left;

  &:hover {
    text-decoration: underline solid
    #F28729;
    color: #4A4A4A!important;
  }
}

.blog-title {
  font-family: AvenirNext-Bold;
  font-size: 24px;
  color: #FFFFFF!important;
  text-align: left;

  &:hover {
    text-decoration: underline solid
    #F28729;
    color: #4A4A4A!important;
  }
}

.blog-summary-title {
  font-family: AvenirNext-Bold;
  font-size: 30px;
  color: #FDFDFD!important;
  text-align: left;

  &:hover {
    text-decoration: underline solid
    #F28729;
    color: #4A4A4A!important;
  }
}

// mobile css for blog
@media only screen and (max-width: 900px) {
  .blog-overview {
    width: 100%;
  }

  .blog-summary-title {
    font-size: 23px;
    text-align: center;
  }
}
