.management-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F7F7F9;
}

.people-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.img-thumbnail {
  height: 17rem;
}

.card-body {
  height: 8rem;
}

.person {
  padding: 2%;
}
