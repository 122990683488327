a.sub-link {
  padding: 5px;
  color: #F28729;
  padding: 2rem;
  font-size: x-large;
  font-weight: 700;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
}

.footer-content-wrapper {
  height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.footer-links {
  display: flex;
  flex-direction: row;
}

.footer-social {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-title {
  font-family: AvenirNext-Bold;
  font-size: 20px;
  color: #4A4A4A;
  text-align: left;
}

.footer-icons {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  font-size: x-large;
}

.social-icon {
  margin: 1rem;

  &:hover {
    text-decoration: underline solid
    #F28729;
    color:orange!important;
  }
}

.copyright {
  font-family: AvenirNext-Medium;
  font-size: 16px;
  color: #4A4A4A;
  text-align: left;
  align-self: center;
}

// mobile css for the footer
@media only screen and (max-width: 900px) {
  .footer-content-wrapper {
    flex-flow: row wrap;
  }

  .footer-wrapper {
    flex-flow: column wrap;
  }

  a.sub-link {
    padding: 5px;
  }

  .footer-links {
    flex-direction: column;
  }

  .social-title {
    text-align: center;
  }

  .copyright {
    font-size: 15px;
  }
}
